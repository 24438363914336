import { Box, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputHeader } from '../Headers/styles';
import { NumberFormatCustom, NumberFormatCustomProps } from './NumberFormatCustom';

type FormInputProps = TextFieldProps & {
  name: string;
  label: string;
  unit?: string;
  numberFormatProps?: NumberFormatCustomProps;
};

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  unit,
  numberFormatProps,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Box mb={2}>
      <InputHeader>{label}</InputHeader>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            error={!!error}
            value={field.value}
            helperText={error?.message}
            variant="outlined"
            fullWidth
            disabled={props.disabled}
            onChange={field.onChange}
            InputProps={{
              inputComponent: props.type === 'number' ? NumberFormatCustom : undefined,
              endAdornment: unit ? (
                <InputAdornment position="end">{unit}</InputAdornment>
              ) : null,
              inputProps: {
                ...numberFormatProps,
                name: field.name,
                onChange: field.onChange,
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default FormInput;
