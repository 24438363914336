import { defineMessages } from 'react-intl'

const messages = defineMessages({
  shipmentInformation: {
    id: 'new_shipment.shipment_information',
    description: 'Shipment Information',
    defaultMessage: 'Shipment Information',
  },
  consignmentPackaging: {
    id: 'new_shipment.consignment_packaging',
    description: 'Consignment Packaging',
    defaultMessage: 'Consignment Packaging',
  },
  consignmentPackagingContents: {
    id: 'new_shipment.consignment_packaging_contents',
    description: 'Consignment Packaging Contents',
    defaultMessage: 'Consignment Packaging Contents',
  },
  summary: {
    id: 'new_shipment.summary',
    description: 'Summary',
    defaultMessage: 'Summary',
  },
  shipFromDeliverTo: {
    id: 'new_shipment.ship_from_deliver_to',
    description: 'Ship From / Deliver To',
    defaultMessage: 'Ship From / Deliver To',
  },
  saveDraft: {
    id: 'new_shipment.save_draft',
    description: 'Save Draft',
    defaultMessage: 'Save Draft',
  },
  consignment: {
    id: 'new_shipment.consignment',
    description: 'Consignment',
    defaultMessage: 'Consignment',
  },
  consignmentReferenceNumber: {
    id: 'new_shipment.consignment_reference_number',
    description: 'Consignment Reference Number',
    defaultMessage: 'Consignment Reference Number',
  },
  consignmentTrackingNumber: {
    id: 'new_shipment.consignment_tracking_number',
    description: 'Consignment Tracking Number',
    defaultMessage: 'Consignment Tracking Number',
  },
  systemGenerated: {
    id: 'new_shipment.system_generated',
    description: 'System Generated',
    defaultMessage: 'System Generated',
  },
  consignmentDescription: {
    id: 'new_shipment.consignment_description',
    description: 'Consignment Description',
    defaultMessage: 'Description',
  },
  currency: {
    id: 'new_shipment.currency',
    description: 'Currency',
    defaultMessage: 'Currency',
  },
  addConsignment: {
    id: 'new_shipment.add_consignment',
    description: 'Add Consignment',
    defaultMessage: 'Add Consignment',
  },
  customer: {
    id: 'new_shipment.customer',
    description: 'Customer',
    defaultMessage: 'Customer',
  },
  customerReferenceNumber: {
    id: 'new_shipment.customer_reference_number',
    description: 'Customer Reference Number',
    defaultMessage: 'Customer Reference Number',
  },
  shipmentNumber: {
    id: 'new_shipment.shipment_number',
    description: 'Shipment Number',
    defaultMessage: 'Shipment Number',
  },
  deliverTo: {
    id: 'new_shipment.deliver_to',
    description: 'Deliver To',
    defaultMessage: 'Deliver To',
  },
  location: {
    id: 'new_shipment.location',
    description: 'Location',
    defaultMessage: 'Location',
  },
  deliveryDate: {
    id: 'new_shipment.delivery_date',
    description: 'Delivery Date & Time',
    defaultMessage: 'Delivery Date',
  },
  contact: {
    id: 'new_shipment.contact',
    description: 'Contact',
    defaultMessage: 'Contact',
  },
  deliveryInstructions: {
    id: 'new_shipment.delivery_instructions',
    description: 'Delivery Instructions',
    defaultMessage: 'Delivery Instructions',
  },
  selectedLocation: {
    id: 'new_shipment.selected_location',
    description: 'Selected Location',
    defaultMessage: 'Selected Location',
  },
  clearingRequired: {
    id: 'new_shipment.clearing_required',
    description: 'Clearing Required',
    defaultMessage: 'Clearing Required',
  },
  showPackageContents: {
    id: 'new_shipment.show_package_contents',
    description: 'Show Package Contents',
    defaultMessage: 'Show Package Contents',
  },
  packageContent: {
    id: 'new_shipment.package_content',
    description: 'Package Content',
    defaultMessage: 'Package Content',
  },
  declaredValue: {
    id: 'new_shipment.declared_value',
    description: 'Declared Value',
    defaultMessage: 'Declared Value',
  },
  specifyContent: {
    id: 'new_shipment.specify_content',
    description: 'Specify Content',
    defaultMessage: 'Specify Contents',
  },
  contentDescription: {
    id: 'new_shipment.content_description',
    description: 'Content Description',
    defaultMessage: 'Content Description',
  },
  unitPrice: {
    id: 'new_shipment.unit_price',
    description: 'Unit Price',
    defaultMessage: 'Unit Price',
  },
  addProduct: {
    id: 'new_shipment.add_product',
    description: 'Add Product',
    defaultMessage: 'Add Product',
  },
  customerInformation: {
    id: 'new_shipment.customer_information',
    description: 'Customer Information',
    defaultMessage: 'Customer Information',
  },
  consignmentInformation: {
    id: 'new_shipment.consignment_information',
    description: 'Consignment Information',
    defaultMessage: 'Consignment Information',
  },
  shipFrom: {
    id: 'new_shipment.ship_from',
    description: 'Ship From',
    defaultMessage: 'Ship From',
  },
  scheduledDate: {
    id: 'new_shipment.scheduled_date',
    description: 'Scheduled Date',
    defaultMessage: 'Scheduled Date',
  },
  addPackaging: {
    id: 'new_shipment.add_packaging',
    description: 'Add Packaging',
    defaultMessage: 'Add Packaging',
  },
  packagingType: {
    id: 'new_shipment.packaging_type',
    description: 'Packaging Type',
    defaultMessage: 'Packaging Type',
  },
  noOfIdenticalPackages: {
    id: 'new_shipment.no_of_identical_packages',
    description: 'Number of Identical Packages`',
    defaultMessage: 'No. of Identical Packages',
  },
  dimensions: {
    id: 'new_shipment.dimensions',
    description: 'Dimensions',
    defaultMessage: 'Dimensions',
  },
  weight: {
    id: 'new_shipment.weight',
    description: 'Weight',
    defaultMessage: 'Weight',
  },
  length: {
    id: 'new_shipment.length',
    description: 'Length',
    defaultMessage: 'Length',
  },
  width: {
    id: 'new_shipment.width',
    description: 'Width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'new_shipment.height',
    description: 'Height',
    defaultMessage: 'Height',
  },
  temperatureControlled: {
    id: 'new_shipment.temperature_controlled',
    description: 'Temperature Controlled',
    defaultMessage: 'Temperature Controlled',
  },
  temperatureRange: {
    id: 'new_shipment.temperature_range',
    description: 'Temperature Range',
    defaultMessage: 'Temperature Range',
  },
  products: {
    id: 'new_shipment.products',
    description: 'Products',
    defaultMessage: 'Products',
  },
  productDescription: {
    id: 'new_shipment.product_description',
    description: 'Product Description',
    defaultMessage: 'Product Description',
  },
  countryOfOrigin: {
    id: 'new_shipment.country_of_origin',
    description: 'Country of Origin',
    defaultMessage: 'Country of Origin',
  },
  quantity: {
    id: 'new_shipment.quantity',
    description: 'Quantity',
    defaultMessage: 'Quantity',
  },
  totalValue: {
    id: 'new_shipment.total_value',
    description: 'Total Value',
    defaultMessage: 'Total Value',
  },
  useProduct: {
    id: 'new_shipment.use_product',
    description: 'Use Product',
    defaultMessage: 'Use Product',
  },
  remove: {
    id: 'new_shipment.remove',
    description: 'Remove',
    defaultMessage: 'Remove',
  },
  containerType: {
    id: 'new_shipment.container_type',
    description: 'Container Type',
    defaultMessage: 'Container Type',
  },
  palletType: {
    id: 'new_shipment.pallet_type',
    description: 'Pallet Type',
    defaultMessage: 'Pallet Type',
  },
  temperatureSetPoint: {
    id: 'new_shipment.temperature_set_point',
    description: 'Temperature Set Point',
    defaultMessage: 'Set Point',
  },
  isStackable: {
    id: 'common.isStackable',
    defaultMessage: 'Is Stackable',
    description: 'The label for the is stackable',
  },
  product: {
    id: 'new_shipment.product',
    description: 'Product',
    defaultMessage: 'Product',
  },
  returnTo: {
    id: 'new_shipment.return_to',
    description: 'Return To',
    defaultMessage: 'Return To',
  },
  sameAsDefault: {
    id: 'new_shipment.same_as_default',
    description: 'Same as Default',
    defaultMessage: 'Same as default ship from',
  },
  different: {
    id: 'new_shipment.different',
    description: 'Different',
    defaultMessage: 'Different',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Description',
    description: 'The label for the description',
  },
  autoGenerated: {
    id: 'common.autoGenerated',
    defaultMessage: 'Auto Generated',
    description: 'The label for the auto generated',
  },
  shipper: {
    id: 'new_shipment.shipper',
    description: 'Shipper',
    defaultMessage: 'Shipper',
  },
  lowerWarning: {
    id: 'new_shipment.lower_warning',
    description: 'Lower Warning',
    defaultMessage: 'Lower Warning',
  },
  upperWarning: {
    id: 'new_shipment.upper_warning',
    description: 'Upper Warning',
    defaultMessage: 'Upper Warning',
  },
  lowerCritical: {
    id: 'new_shipment.lower_critical',
    description: 'Lower Critical',
    defaultMessage: 'Lower Critical',
  },
  upperCritical: {
    id: 'new_shipment.upper_critical',
    description: 'Upper Critical',
    defaultMessage: 'Upper Critical',
  },
  modeOfTransit: {
    id: 'new_shipment.mode_of_transit',
    description: 'Mode of Transit',
    defaultMessage: 'Mode of Transit',
  },
  noPreference: {
    id: 'new_shipment.no_preference',
    description: 'No Preference',
    defaultMessage: 'No Preference',
  },
  preferredCarrier: {
    id: 'new_shipment.preferred_carrier',
    description: 'Preferred Carrier',
    defaultMessage: 'Preferred Carrier',
  },
  preBookedTransit: {
    id: 'new_shipment.pre_booked_transit',
    description: 'Pre-booked Transit',
    defaultMessage: 'Pre-booked Transit',
  },
  carrier: {
    id: 'new_shipment.carrier',
    description: 'Carrier',
    defaultMessage: 'Carrier',
  },
  transitIdentifier: {
    id: 'new_shipment.transit_identifier',
    description: 'Transit Identifier',
    defaultMessage: 'Transit Identifier',
  },
  enterCarrier: {
    id: 'new_shipment.enter_carrier',
    description: 'Enter carrier',
    defaultMessage: 'Enter carrier',
  },
  enterTransitIdentifier: {
    id: 'new_shipment.enter_transit_identifier',
    description: 'Enter transit identifier',
    defaultMessage: 'Enter transit identifier',
  },
  crossBorder: {
    id: 'new_shipment.cross_border',
    description: 'Cross Border',
    defaultMessage: 'Cross Border',
  },
  transitDetails: {
    id: 'new_shipment.transit_details',
    description: 'Transit Details',
    defaultMessage: 'Transit Details',
  },
  showTransitDetails: {
    id: 'new_shipment.show_transit_details',
    description: 'Show Transit Details',
    defaultMessage: 'Show Transit Details',
  },
  addWaypoint: {
    id: 'new_shipment.add_waypoint',
    description: 'Add Waypoint',
    defaultMessage: 'Add Waypoint',
  },
  waypoint: {
    id: 'new_shipment.waypoint',
    description: 'Waypoint',
    defaultMessage: 'Waypoint',
  },
  shipmentUpdated: {
    id: 'new_shipment.shipment_updated',
    description: 'Shipment updated',
    defaultMessage: 'Shipment updated',
  },
  shipmentCreated: {
    id: 'new_shipment.shipment_created',
    description: 'Shipment created',
    defaultMessage: 'Shipment created',
  },
  acceptShipment: {
    id: 'new_shipment.accept_shipment',
    description: 'Accept Shipment',
    defaultMessage: 'Accept',
  },
  submitShipment: {
    id: 'new_shipment.submit_shipment',
    description: 'Submit Shipment',
    defaultMessage: 'Submit',
  },
  shipmentAcceptFailed: {
    id: 'new_shipment.shipment_accept_failed',
    description: 'Failed to accept shipment',
    defaultMessage: 'Failed to accept shipment',
  },
  shipmentAccepted: {
    id: 'new_shipment.shipment_accepted',
    description: 'Shipment accepted',
    defaultMessage: 'Shipment accepted',
  },
  status: {
    id: 'new_shipment.status',
    description: 'Status',
    defaultMessage: 'Status',
  },
  lockForReview: {
    id: 'new_shipment.lock_for_review',
    description: 'Lock for Review',
    defaultMessage: 'Under Review',
  },
  acceptedStatus: {
    id: 'new_shipment.accepted_status',
    description: 'Accepted',
    defaultMessage: 'Accepted',
  },
  submittedStatus: {
    id: 'new_shipment.submitted_status',
    description: 'Submitted',
    defaultMessage: 'Submitted',
  },
  draftStatus: {
    id: 'new_shipment.draft_status',
    description: 'Draft',
    defaultMessage: 'Draft',
  },
  newShipment: {
    id: 'new_shipment.new_shipment',
    description: 'New Shipment',
    defaultMessage: 'New Shipment',
  },
  shipments: {
    id: 'new_shipment.shipments',
    description: 'Shipments',
    defaultMessage: 'Shipments',
  },
  submittedDate: {
    id: 'new_shipment.submitted_date',
    description: 'Submitted Date',
    defaultMessage: 'Submitted Date',
  },
  acceptedDate: {
    id: 'new_shipment.accepted_date',
    description: 'Accepted Date',
    defaultMessage: 'Accepted Date',
  },
  shipmentUpdateSuccess: {
    id: 'new_shipment.shipmentUpdateSuccess',
    description: 'Shipment update success message',
    defaultMessage: 'Shipment updated successfully',
  },
  shipmentUpdateError: {
    id: 'new_shipment.shipmentUpdateError',
    description: 'Shipment update error message',
    defaultMessage: 'Shipment update failed',
  },
  countdownTitle: {
    id: 'new_shipment.countdown_title',
    description: 'Countdown Title',
    defaultMessage: 'Countdown {value}',
  },
  backToShipmentstBtn: {
    id: 'new_shipment.back_to_shipments_btn',
    description: 'Back to Shipments',
    defaultMessage: 'Back to Shipments',
  },
  buttonReject: {
    id: 'new_shipment.button_reject',
    description: 'Reject',
    defaultMessage: 'Reject',
  },
  shipmentRejected: {
    id: 'new_shipment.shipment_rejected',
    description: 'Shipment rejected',
    defaultMessage: 'Shipment rejected',
  },
  shipmentRejectFailed: {
    id: 'new_shipment.shipment_reject_failed',
    description: 'Failed to reject shipment',
    defaultMessage: 'Failed to reject shipment',
  },
  buttonRecall: {
    id: 'new_shipment.button_recall',
    description: 'Recall',
    defaultMessage: 'Recall',
  },
  shipmentRecalled: {
    id: 'new_shipment.shipment_recalled',
    description: 'Shipment recalled',
    defaultMessage: 'Shipment recalled',
  },
  shipmentRecallFailed: {
    id: 'new_shipment.shipment_recall_failed',
    description: 'Failed to recall shipment',
    defaultMessage: 'Failed to recall shipment',
  },
  shipmentSubmittedSuccess: {
    id: 'new_shipment.shipmentSubmittedSuccess',
    description: 'Shipment submitted success message',
    defaultMessage: 'Shipment submitted successfully',
  },
  shipmentSubmitFailed: {
    id: 'new_shipment.shipmentSubmitFailed',
    description: 'Shipment submit failed message',
    defaultMessage: 'Shipment submit failed',
  },
  shipmentTrackingNumber: {
    id: 'new_shipment.shipment_tracking_number',
    description: 'Shipment Tracking Number',
    defaultMessage: 'Shipment Tracking Number',
  },
  cargoValue: {
    id: 'new_shipment.cargo_value',
    description: 'Cargo Value',
    defaultMessage: 'Cargo Value',
  },
  acceptShipments: {
    id: 'new_shipment.accept_shipments',
    description: 'Accept Shipments',
    defaultMessage: 'Accept Shipments',
  },
  shipmentSubmissionFailed: {
    id: 'new_shipment.shipment_submission_failed',
    description: 'Shipment submission failed',
    defaultMessage: 'Shipment submission failed',
  },
  unitLabel: {
    id: 'new_shipment.unit_name',
    description: 'Unit Name',
    defaultMessage: 'UOM',
  },
})
export default messages
