import {
    Box,
    InputAdornment,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputHeader } from '../Headers/styles';
import { NumberFormatCustom, NumberFormatCustomProps } from './NumberFormatCustom';

interface CombinedSelectProps {
    nameValue: string;
    nameUnit: string;
    labelValue: string | number;
    labelUnitValue?: string;
    optionsUnit: { label: string; value: string | number }[];
    type?: 'text' | 'number';
    numberFormatProps?: NumberFormatCustomProps;

}


const CombinedSelect: React.FC<CombinedSelectProps> = ({
    nameValue,
    nameUnit,
    labelValue,
    optionsUnit,
    numberFormatProps,
    labelUnitValue,
    type = 'number',
}) => {
    const { control } = useFormContext();

    return (
        <Box mb={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputHeader>{labelValue}</InputHeader>
                <InputHeader>{labelUnitValue}</InputHeader>
            </Box>
            <Controller
                name={nameValue}
                control={control}
                render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                    <TextField
                        label={labelValue}
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        InputProps={{
                            inputComponent: type === 'number' ? NumberFormatCustom : undefined,
                            inputProps: {
                                ...numberFormatProps,
                                name: name,
                                onChange: onChange,
                                ref: ref,
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Controller
                                        name={nameUnit}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                variant="standard"
                                                disableUnderline
                                                renderValue={(selected) => {
                                                    return optionsUnit.find((option) => option.value === selected)?.label;
                                                }}
                                            >
                                                {optionsUnit.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default CombinedSelect;
