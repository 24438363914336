import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, CircularProgress, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useNotificationStack } from '../../app/hooks/useNotificationStack';
import { useAcceptShipmentByTenantIdMutation, useCreateShipmentMutation, useSubmitShipmentMutation, useUpdateShipmentMutation } from '../../app/redux-fetch/apiShipment';
import { PageHeader } from '../../components/Headers/styles';
import commonMessages from '../../components/Messages/commonMessages';
import { NotiStackOptions } from '../utils';
import ConsignmentList from './ConsignmentList';
import { ShipmentFormProvider } from './context/ShipmentFormContext';
import messages from './messages';
import ShipmentInformation from './ShipmentInformation';
import ShipmentSummary from './ShipmentSummary';
import { mapToShipmentData } from './utils';


const schema = z.object({
  id: z.number().optional(),
  tenantId: z.number().min(1, { message: 'Tenant is required' }),
  customerId: z.number().min(1, { message: 'Customer is required' }),
  shipperId: z.number().optional(),
  revision: z.number().optional(),
  customerReferenceNumber: z
    .string()
    .min(1, { message: 'Customer Reference Number is required' }),
  shipmentNumber: z.string().optional(),
  description: z.string().optional(),
  consignments: z
    .array(
      z.object({
        id: z.number().optional(),
        referenceNumber: z.string(),
        trackingNumber: z.string(),
        currencyId: z
          .number({ message: 'Currency is required' })
          .min(1, { message: 'Currency is required' }),
        description: z.string().optional(),
        returnTo: z.object({
          locationId: z.number().optional(),
          contactId: z.number().optional(),
          defaultValue: z.boolean().optional(),
        }).optional(),
        shipFrom: z.object({
          id: z.number().optional(),
          locationId: z
            .number({ message: 'Ship From location is required' })
            .min(1, { message: 'Ship From location is required' }),
          consignmentId: z.number().optional(),
          returnTo: z.boolean().optional(),
          contactId: z.number().nullable().optional(),
          clearingRequired: z.boolean().optional(),
          defaultValue: z.boolean().optional(),
          loadingDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
          transitDetails: z.object({
            id: z.number().optional(),
            carrierId: z.number().optional(),
            crossBorder: z.boolean().optional(),
            modeOfTransit: z.number().optional(),
            transitIdentifier: z.string().optional(),
            consignmentStopId: z.number().optional(),
          }).optional(),
        }),
        deliverTo: z.object({
          id: z.number().optional(),
          consignmentId: z.number().optional(),
          locationId: z
            .number({ message: 'Deliver To location is required' })
            .min(1, { message: 'Deliver To location is required' }),
          clearingRequired: z.boolean().optional(),
          contactId: z.number().nullable().optional(),
          deliveryDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
          deliveryInstructions: z.string().optional(),
        }),
        packaging: z.array(
          z.object({
            id: z.number().optional(),
            packagingTemplateId: z.number().optional(),
            physicalPropertiesId: z.number().optional(),
            packagingTypeId: z.number().optional(),
            сonsignmentId: z.number().optional(),
            packagingDetailId: z.number().optional(),
            numberOfPackages: z.number({ message: 'Number of packages is required' }).optional(),
            useDeclaredValue: z.boolean().optional(),
            declaredContentDescription: z.string().optional(),
            weight: z.number().optional(),
            length: z.number().optional(),
            width: z.number().optional(),
            height: z.number().optional(),
            temperatureControlled: z.boolean().optional(),
            temperatureRange: z.number().optional(),
            insuranceRequired: z.boolean().optional(),
            amountInsured: z.number().optional(),
            setPointUnitId: z.number().optional(),
            setPoint: z.number().optional(),
            lowerWarning: z.number().optional(),
            upperWarning: z.number().optional(),
            lowerCritical: z.number().optional(),
            isStackable: z.boolean().optional(),
            upperCritical: z.number().optional(),
            weightUnit: z.number().optional(),
            dimensionUnit: z.number().optional(),
            declaredValue: z.number().optional(),
            products: z.array(
              z.object({
                id: z.number().optional(),
                productDescription: z.string().optional(),
                productId: z.number().optional(),
                countryOfOriginId: z.number().optional(),
                quantity: z.number(),
                unitPrice: z.number(),
                useProduct: z.boolean().optional(),
                defaultValue: z.boolean().optional(),
              })
            ).optional(),
          })
        ).optional(),
        waypoints: z.array(
          z.object({
            id: z.number().optional(),
            locationId: z
              .number({ message: 'Waypoint location is required' })
              .min(1, { message: 'Waypoint location is required' }),
            clearingRequired: z.boolean().optional(),
            loadingDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
            deliveryInstructions: z.string().optional(),
            consignmentId: z.number().optional(),
            contactId: z.number().nullable().optional(),
            transitDetails: z.object({
              id: z.number().optional(),
              crossBorder: z.boolean().optional(),
              modeOfTransit: z.number().optional(),
              transitIdentifier: z.string().optional(),
              carrierId: z.number().optional(),
              consignmentStopId: z.number().optional(),
            }).optional(),
          })
        ),
      })
        .superRefine((data, context) => {

          const loadingDate = data.shipFrom.loadingDate;
          const deliveryDate = data.deliverTo.deliveryDate;

          if (data.waypoints && data.waypoints.length > 0) {
            let previousDate = loadingDate;

            data.waypoints.forEach((waypoint, index) => {
              const waypointDate = waypoint.loadingDate;

              if (!waypointDate) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Waypoint ${index + 1}: Loading date is required`,
                  path: ['waypoints', index, 'loadingDate'],
                });
              } else if (waypointDate <= previousDate) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Waypoint ${index + 1}: Loading date must be after previous location's date`,
                  path: ['waypoints', index, 'loadingDate'],
                });
              }

              previousDate = waypointDate;
            });

            const lastWaypointDate = data.waypoints[data.waypoints.length - 1].loadingDate;

            if (deliveryDate && lastWaypointDate && deliveryDate <= lastWaypointDate) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Delivery date must be after last waypoint loading date',
                path: ['deliverTo', 'deliveryDate'],
              });
            }
          } else {
            if (loadingDate && deliveryDate && deliveryDate <= loadingDate) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Delivery date must be after loading date',
                path: ['deliverTo', 'deliveryDate'],
              });
            }
          }
        })
    ).min(1, { message: 'At least one consignment is required' }),
});


export type IShipmentFormInput = z.infer<typeof schema>;

interface ShipmentFormProps {
  initialValues?: Partial<IShipmentFormInput>;
  onSubmit: (data: IShipmentFormInput) => void;
  isEdit?: boolean;
  onCancel?: () => void;
}

const displayErrors = (errors: FieldErrors<IShipmentFormInput>, enqueueFailure: any) => {
  const buildErrorMessages = (errors: FieldErrors<IShipmentFormInput>, path = ''): string[] => {
    return Object.entries(errors).flatMap(([key, error]) => {
      const currentPath = path ? `${path}.${key}` : key;

      if (error && 'message' in error) {
        return `${currentPath}: ${error.message}`;
      } else if (typeof error === 'object') {
        return buildErrorMessages(error as FieldErrors<IShipmentFormInput>, currentPath);
      }

      return [];
    });
  };

  const formattedErrors = buildErrorMessages(errors).join('\n');
  buildErrorMessages(errors).forEach((error) => {
    enqueueFailure(error, NotiStackOptions.error);
  });
}

const ShipmentForm: React.FC<ShipmentFormProps> = ({
  initialValues,
  isEdit = false,
  onSubmit,
}) => {
  const { tenantId, customerId } = useLoggedInUser();

  const { formatMessage } = useIntl();

  const methods = useForm<IShipmentFormInput>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: initialValues || {
      tenantId: tenantId,
      customerId: customerId,
      consignments: [
        {
          shipFrom: {},
          deliverTo: {},
        },
      ],
    },
  });

  const { handleSubmit, reset, formState: { errors }, watch } = methods

  React.useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentPage, setCurrentPage] = useState(1);

  const [createShipment, { isLoading }] = useCreateShipmentMutation();
  const [updateShipment, { isLoading: isUpdating }] = useUpdateShipmentMutation();

  const [submitShipment] = useSubmitShipmentMutation();
  const [acceptShipment] = useAcceptShipmentByTenantIdMutation();

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const navigate = useNavigate();

  const handleSubmitForm = async (
    data: IShipmentFormInput,
    onAfterRequest?: (responseData?: any) => void
  ) => {
    const payload = mapToShipmentData(data);
    if (payload) {
      let response;
      try {
        if (isEdit) {
          response = await updateShipment({ shipment: payload, tenantId, customerId }).unwrap();
          enqueueSuccess(messages.shipmentUpdated);
        } else {
          response = await createShipment({ shipment: payload, tenantId, customerId }).unwrap();
          enqueueSuccess(messages.shipmentCreated);
        }
      } catch (error) {
        console.error('Failed to submit shipment:', error);
        enqueueFailure(messages.shipmentSubmissionFailed);
      } finally {
        if (onAfterRequest) {
          onAfterRequest(response);
        }
        if (response && !isEdit) {
          navigate(`/shipping/shipments/${response.id}/edit`);
        }
      }
    }
  };

  const handleSubmitShipment = async () => {
    const data = methods.getValues();
    handleSubmitForm(data, async (response) => {
      if (response) {
        try {
          await submitShipment({ tenantId, shipmentId: response.id }).unwrap();
          await acceptShipment({ tenantId, shipmentId: response.id }).unwrap();
          enqueueSuccess(messages.shipmentAccepted);
          navigate('/shipping/shipments');
        } catch (error) {
          enqueueFailure(messages.shipmentAcceptFailed);
        }
      }
    })
  }

  const handleAccept = async () => {
    const data = methods.getValues();
    handleSubmitForm(data, async (response) => {
      if (response) {
        try {
          await acceptShipment({ tenantId, shipmentId: response.id }).unwrap();
          enqueueSuccess(messages.shipmentAccepted);
          navigate('/shipping/shipments');
        } catch (error) {
          enqueueFailure(messages.shipmentAcceptFailed);
        }
      }
    })
  }


  const handleNext = async () => {
    let isValid = false;
    await methods.trigger();
    if (currentPage === 1) {
      isValid = await methods.trigger(['customerId', 'customerReferenceNumber']);
    } else if (currentPage === 2) {
      isValid = await methods.trigger(['consignments']);
    } else {
      isValid = true;
    }

    if (isValid) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleSaveDraft = () => {
    const data = methods.getValues();
    handleSubmitForm(data)
  }

  const handleBack = () => {
    if (currentPage === 1) {
      return
    }
    setCurrentPage((prev) => prev - 1);
  }

  const header = () => {
    return currentPage === 4
      ? formatMessage(messages.summary)
      : formatMessage(messages.shipmentInformation);
  }

  const consignments = watch('consignments');

  const isStepValid = useCallback(() => {
    switch (currentPage) {
      case 1:
        return !(errors.customerId || errors.customerReferenceNumber || errors.consignments || (!Array.isArray(consignments) || consignments?.length === 0));
      case 2:
        return !errors.consignments;
      default:
        return true;
    }
  }, [currentPage, errors, consignments]);

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name?.includes('shipFrom.loadingDate') || name?.includes('deliverTo.deliveryDate')) {
        methods.trigger();
      }
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  return (
    <Paper elevation={3} sx={{ padding: 4 }}>
      <Box
        sx={{
          backgroundColor: '#f0f0f0',
          padding: 2,
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
        <Typography variant="h5">{formatMessage(messages.shipFromDeliverTo)}</Typography>
      </Box>
      <ShipmentFormProvider currentPage={currentPage} totalPages={3} setCurrentPage={setCurrentPage}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection={isSmallScreen ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={isSmallScreen ? 'flex-start' : 'center'}
              sx={{ marginBottom: 2 }}
            >
              <PageHeader>{header()}</PageHeader>
              <Box>
                {currentPage > 1 && (<Button
                  variant="contained"
                  sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
                  onClick={handleBack}
                >
                  {formatMessage(commonMessages.previous)}
                </Button>)}

                <Button variant="contained" color="primary" onClick={handleSaveDraft}
                  sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} />
                  ) : (
                    formatMessage(messages.saveDraft)
                  )}
                </Button>
                <Button variant="contained" color="primary" onClick={currentPage === 4 ? handleAccept : handleNext} disabled={!isStepValid()}
                >
                  {currentPage === 4 ? formatMessage(commonMessages.accept) : formatMessage(commonMessages.next)}
                </Button>
                {currentPage === 4 && (<>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                    onClick={handleSubmitShipment}
                  >
                    {formatMessage(messages.submitShipment)}
                  </Button>
                </>
                )}
              </Box>
            </Box>
            {currentPage !== 4 ? (
              <>
                <ShipmentInformation isEdit={isEdit} />
                {currentPage > 0 && (<ConsignmentList />)}
              </>
            ) : (currentPage === 4 && (<ShipmentSummary data={methods.getValues()} />))}
          </form>
        </FormProvider>
      </ShipmentFormProvider>
    </Paper>
  )
}

export default ShipmentForm;
