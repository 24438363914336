import { useEffect, useState } from 'react';
import { useModalWithForm } from '../../../app/hooks/useModalWithForm';
import { useGetProductsQuery } from '../../../app/redux-fetch/apiQuery';
import { convertToLabelValue } from '../../../app/utils';
import { Option } from '../../../components/Forms/FormSelect';

interface UseProductModalProps {
    onSave: (data: any) => void;
    customerId?: number;
}

export const useProductModal = ({ onSave, customerId }: UseProductModalProps) => {

    const [productOptions, setProductOptions] = useState<Option[]>([]);

    const { data: products = [] } = useGetProductsQuery(customerId ?? -1);


    useEffect(() => {
        setProductOptions(convertToLabelValue(products));
    }, [products]);

    const { isModalOpen, openModal, closeModal, ModalComponent } = useModalWithForm({
        type: 'productModal',
        onSave: (createdProduct) => {
            onSave(createdProduct.data.id);
        },
        data: {
            customerId: customerId,
            enabled: true,
        },
    });

    return {
        productOptions,
        products,
        isModalOpen,
        openModal,
        closeModal,
        ModalComponent,
    };
};
