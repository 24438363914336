import {
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    Edge,
    Node,
    ReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import React, { useCallback, useEffect, useState } from 'react';
import { IShipmentFormInput } from './ShipmentForm';

interface ShipmentDiagramProps {
    shipmentData: IShipmentFormInput;
}

const ShipmentDiagram: React.FC<ShipmentDiagramProps> = ({ shipmentData }) => {
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);

    useEffect(() => {
        const { customerReferenceNumber, consignments } = shipmentData;

        const newNodes: Node[] = [
            {
                id: 'shipment',
                data: { label: `Shipment: ${customerReferenceNumber}` },
                position: { x: 0, y: 0 },
                type: 'input',
                style: {
                    backgroundColor: '#e3f2fd',
                    border: '2px solid #0041d0',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                },
            },
        ];

        const newEdges: Edge[] = [];

        consignments?.forEach((consignment, index) => {
            const consignmentId = `consignment-${index}`;

            newNodes.push({
                id: consignmentId,
                data: {
                    label: `Consignment ${index + 1}: ${consignment.referenceNumber || 'N/A'
                        }`,
                },
                position: { x: 0, y: (index + 1) * 150 },
                style: {
                    backgroundColor: '#e0f7fa',
                    border: '2px solid #00d0ff',
                    borderRadius: '5px',
                },
            });

            newEdges.push({
                id: `e-shipment-consignment-${index}`,
                source: 'shipment',
                target: consignmentId,
                type: 'step',
                label: 'Consignment',
                style: {
                    stroke: '#0041d0',
                    strokeWidth: 2,
                },
                labelStyle: {
                    fill: '#0041d0',
                    fontWeight: 'bold',
                },
            });

            consignment.packaging?.forEach((pkg: any, pkgIndex: any) => {
                const pkgId = `packaging-${index}-${pkgIndex}`;
                newNodes.push({
                    id: pkgId,
                    data: {
                        label: `Packaging: ${pkg.packagingType} (Qty: ${pkg.numberOfPackages})`,
                    },
                    position: {
                        x: 200,
                        y: (index + 1) * 150 + pkgIndex * 100,
                    },
                    style: {
                        backgroundColor: '#fff3e0',
                        border: '2px solid #ff9800',
                        borderRadius: '5px',
                    },
                });

                newEdges.push({
                    id: `e-${consignmentId}-${pkgId}`,
                    source: consignmentId,
                    target: pkgId,
                    type: 'step',
                    label: 'Packaging',
                    style: {
                        stroke: '#ff9800',
                        strokeWidth: 2,
                    },
                    labelStyle: {
                        fill: '#ff9800',
                        fontWeight: 'bold',
                    },
                });

                pkg.products?.forEach((product: any, prodIndex: any) => {
                    const productId = `product-${index}-${pkgIndex}-${prodIndex}`;
                    newNodes.push({
                        id: productId,
                        data: { label: `Product: ${product.productDescription}` },
                        position: {
                            x: 400,
                            y:
                                (index + 1) * 150 +
                                pkgIndex * 100 +
                                prodIndex * 50,
                        },
                        style: {
                            backgroundColor: '#e8f5e9',
                            border: '2px solid #4caf50',
                            borderRadius: '5px',
                        },
                    });

                    newEdges.push({
                        id: `e-${pkgId}-${productId}`,
                        source: pkgId,
                        target: productId,
                        type: 'step',
                        label: 'Product',
                        style: {
                            stroke: '#4caf50',
                            strokeWidth: 2,
                        },
                        labelStyle: {
                            fill: '#4caf50',
                            fontWeight: 'bold',
                        },
                    });
                });
            });

            consignment.waypoints?.forEach((waypoint, wpIndex) => {
                const waypointId = `waypoint-${index}-${wpIndex}`;
                newNodes.push({
                    id: waypointId,
                    data: {
                        label: `Waypoint ${wpIndex + 1}: ${waypoint.locationId}`,
                    },
                    position: {
                        x: -200,
                        y: (index + 1) * 150 + wpIndex * 100,
                    },
                    style: {
                        backgroundColor: '#f3e5f5',
                        border: '2px solid #9c27b0',
                        borderRadius: '5px',
                    },
                });

                newEdges.push({
                    id: `e-${consignmentId}-${waypointId}`,
                    source: consignmentId,
                    target: waypointId,
                    type: 'step',
                    label: 'Waypoint',
                    style: {
                        stroke: '#9c27b0',
                        strokeWidth: 2,
                    },
                    labelStyle: {
                        fill: '#9c27b0',
                        fontWeight: 'bold',
                    },
                });
            });

            const shipFromId = `shipfrom-${index}`;
            newNodes.push({
                id: shipFromId,
                data: {
                    label: `Ship From: ${consignment.shipFrom?.locationId || 'N/A'}`,
                },
                position: { x: -400, y: (index + 1) * 150 },
                style: {
                    backgroundColor: '#ffebee',
                    border: '2px solid #f44336',
                    borderRadius: '5px',
                },
            });

            newEdges.push({
                id: `e-${shipFromId}-${consignmentId}`,
                source: shipFromId,
                target: consignmentId,
                type: 'step',
                label: 'Ship From',
                style: {
                    stroke: '#f44336',
                    strokeWidth: 2,
                },
                labelStyle: {
                    fill: '#f44336',
                    fontWeight: 'bold',
                },
            });

            const deliverToId = `deliverto-${index}`;
            newNodes.push({
                id: deliverToId,
                data: {
                    label: `Deliver To: ${consignment.deliverTo?.locationId || 'N/A'}`,
                },
                position: { x: -600, y: (index + 1) * 150 },
                style: {
                    backgroundColor: '#ffebee',
                    border: '2px solid #f44336',
                    borderRadius: '5px',
                },
            });

            newEdges.push({
                id: `e-${consignmentId}-${deliverToId}`,
                source: consignmentId,
                target: deliverToId,
                type: 'step',
                label: 'Deliver To',
                style: {
                    stroke: '#f44336',
                    strokeWidth: 2,
                },
                labelStyle: {
                    fill: '#f44336',
                    fontWeight: 'bold',
                },
            });
        });

        setNodes(newNodes);
        setEdges(newEdges);
    }, [shipmentData]);

    const onNodesChange = useCallback(
        (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
        []
    );
    const onEdgesChange = useCallback(
        (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        []
    );

    return (
        <div style={{ height: '600px', width: '100%' }}>
            <ReactFlow
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                fitView
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
};

export default ShipmentDiagram;
