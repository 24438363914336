import { Box, Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useGetCustomersQuery, useGetMeasureUnitsQuery } from '../../app/redux-fetch/apiQuery';
import { useGetAllPackagingTemplatesQuery, useGetAllPackagingTypesQuery, useGetCountriesQuery, useGetCurrenciesByCustomerIdQuery, useGetLocationsQuery } from '../../app/redux-fetch/apiShipment';
import BoxWhiteContainer from '../../components/Containers/BoxWhiteContainer';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../components/Typographies/LabelItem';
import ShipmentDiagram from './ShipmentDiagram';
import { IShipmentFormInput } from './ShipmentForm';
import { getCustomerName } from './utils';

type ShipmentSummaryProps = {
    data: IShipmentFormInput
}

const ShipmentSummary: React.FC<ShipmentSummaryProps> = ({ data }) => {
    const isSmallScreen = false;

    const { tenantId } = useLoggedInUser();

    const { watch } = useFormContext()

    const { data: customers = [] } = useGetCustomersQuery(data.tenantId)

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: data.customerId ?? 0, hideDisabled: true,
    })

    const { data: locations = [] } = useGetLocationsQuery({
        tenantId: data.tenantId,
        customerId: undefined,
    });

    const { data: countries = [] } = useGetCountriesQuery()


    const { data: measureUnits = [] } = useGetMeasureUnitsQuery();

    const { data: packagingTypes = [] } = useGetAllPackagingTypesQuery();

    const { data: packagingTemplates = [] } = useGetAllPackagingTemplatesQuery();

    const getCountryName = (countryId?: number) => {
        if (countryId === undefined) return 'N/A';
        const country = countries.find((country: any) => country.id === countryId);
        return country?.name || 'N/A';
    }

    const getCurrencyLabel = (currencyId?: number) => {
        if (currencyId === undefined) return 'N/A';
        const currency = currencies.find((curr) => curr.id === currencyId);
        return currency?.iso3 || 'N/A';
    }

    const getPackagingTemplateName = (templateId?: number) => {
        if (templateId === undefined) return 'N/A';
        const template = packagingTemplates.find((temp: any) => temp.id === templateId);
        return template?.name || 'N/A';
    }

    const getPackagingTypeName = (typeId?: number) => {
        if (typeId === undefined) return 'N/A';
        const type = packagingTypes.find((packagingType: any) => packagingType.id === typeId);
        return type?.name || 'N/A';
    }

    const getLocationName = (locationId?: number) => {
        if (locationId === undefined) return 'N/A';
        const location = locations.find((loc) => loc.id === locationId);
        return location?.name || 'N/A';
    }

    const shipmentData = watch()

    return (
        <Box>
            <BoxWhiteContainer sx={{ mb: '32px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <LabelItem>Customer Information</LabelItem>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InfoDisplayItem
                            label="Customer"
                            value={getCustomerName(customers, data.customerId) || 'N/A'}
                            isSmallScreen={isSmallScreen}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InfoDisplayItem
                            label="Customer Reference Number"
                            value={data.customerReferenceNumber || 'N/A'}
                            isSmallScreen={isSmallScreen}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InfoDisplayItem
                            label="Shipment Number"
                            value={getLocationName(data.shipperId) || 'N/A'}
                            isSmallScreen={isSmallScreen}
                        />
                    </Grid>
                </Grid>
            </BoxWhiteContainer>

            {data.consignments &&
                data.consignments.map((consignment: any, cIndex: number) => (
                    <BoxWhiteContainer key={cIndex} sx={{ mb: '32px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <LabelItem>Consignment #{cIndex + 1}</LabelItem>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label="Consignment Reference"
                                    value={
                                        consignment.referenceNumber || 'N/A'
                                    }
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label="Tracking Number"
                                    value={
                                        consignment.trackingNumber || 'N/A'
                                    }
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} md={3}>
                                <LabelItem>Ship From / Deliver To</LabelItem>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label="Ship From"
                                    value={getLocationName(consignment.shipFrom?.locationId)}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label="Deliver To"
                                    value={getLocationName(consignment.deliverTo?.locationId)}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                        </Grid>

                        {consignment.packaging &&
                            consignment.packaging.map((packaging: any, pIndex: number) => (
                                <Box key={pIndex} sx={{ mt: 2 }}>
                                    <LabelItem>Packaging #{pIndex + 1}</LabelItem>
                                    <Grid container spacing={2} sx={{ pl: '16px' }}>
                                        <Grid item xs={12} md={6}>
                                            <InfoDisplayItem
                                                label="Packaging Type"
                                                value={getPackagingTypeName(packaging.packagingTypeId)}
                                                isSmallScreen={isSmallScreen}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <InfoDisplayItem
                                                label="Number of Packages"
                                                value={packaging.numberOfPackages || 'N/A'}
                                                isSmallScreen={isSmallScreen}
                                            />
                                        </Grid>
                                    </Grid>

                                    {packaging.products &&
                                        packaging.products.map((product: any, prodIndex: number) => (
                                            <Box key={prodIndex} sx={{ mt: 1 }}>
                                                <LabelItem>Product #{prodIndex + 1}</LabelItem>
                                                <Grid container spacing={2} sx={{ pl: '16px' }}>
                                                    <Grid item xs={12} md={3}>
                                                        <InfoDisplayItem
                                                            label="Description"
                                                            value={product.productDescription || 'N/A'}
                                                            isSmallScreen={isSmallScreen}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <InfoDisplayItem
                                                            label="Country of Origin"
                                                            value={getCountryName(product.countryOfOriginId)}
                                                            isSmallScreen={isSmallScreen}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <InfoDisplayItem
                                                            label="Quantity"
                                                            value={product.quantity || 'N/A'}
                                                            isSmallScreen={isSmallScreen}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <InfoDisplayItem
                                                            label="Unit Price"
                                                            value={`${product.unitPrice} ${getCurrencyLabel(consignment.currencyId)}` || 'N/A'}
                                                            isSmallScreen={isSmallScreen}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                </Box>
                            ))}
                    </BoxWhiteContainer>
                ))}
            <ShipmentDiagram shipmentData={shipmentData as IShipmentFormInput} />
        </Box>
    );
};

export default ShipmentSummary;