import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ConsignmentForm from './ConsignmentForm';
import messages from './messages';

const ConsignmentList: React.FC = () => {
    const { control, trigger } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'consignments',
    });

    const { formatMessage } = useIntl();

    const copyConsignment = (index: number) => {
        const consignment = fields[index];
        append({ ...consignment });
    };

    return (
        <>
            {fields.map((field, index) => (
                <ConsignmentForm
                    key={field.id}
                    index={index}
                    removeConsignment={() => remove(index)}
                    copyConsignment={() => copyConsignment(index)}
                />
            ))}

            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={async () => {
                    await trigger(['consignments'])
                    append({
                        consignmentReferenceNumber: '',
                        trackingNumber: '',
                        shipFrom: {
                            location: '',
                            transitDetails: undefined
                        },
                        deliverTo: {
                            location: '',
                        },
                        packaging: [{ packagingType: '', numberOfPackages: 1, products: [] }],
                    })
                }
                }
                sx={{ marginTop: 2 }}
            >
                {formatMessage(messages.addConsignment)}
            </Button >
        </>
    );
};

export default ConsignmentList;
