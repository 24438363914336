import RoomIcon from '@mui/icons-material/Room';
import {
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetContactByEntityIdQuery } from '../../app/redux-fetch/apiCoreQuery';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import FormDatePickerInput from '../../components/Forms/FormDatePickerInput';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../components/Typographies/LabelItem';
import FlowSection from './FlowSection';
import { useLocationModal } from './hooks/useLocationModal';
import messages from './messages';
import ReturnTo from './ReturnTo';
import { getAvailableLocations } from './utils';
import WaypointContainer from './WaypointContainer';

interface ShipFromFormProps {
  index: number;
}

const ShipFromForm: React.FC<ShipFromFormProps> = ({ index }) => {
  const { control, setValue, watch } = useFormContext();
  const [expanded, setExpanded] = useState(true);
  const { formatMessage } = useIntl();

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  const customerId = watch('customerId');

  const { openModal, ModalComponent, locationOptions } = useLocationModal({
    onSave: (id) => {
      setValue(`consignments.${index}.shipFrom.location`, id);
    },
    customerId,
  });

  const getLocationName = (locationId: number) => {
    return locationOptions.find((location) => location.value === locationId)?.label;
  }

  const shipFrom = watch(`consignments.${index}.shipFrom.locationId`);

  const loadingDate = dayjs(watch(`consignments.${index}.shipFrom.loadingDate`)).format('DD/MM/YYYY');


  const { data: contacts } = useGetContactByEntityIdQuery(shipFrom, {
    skip: !shipFrom,
    refetchOnMountOrArgChange: true,
  })

  const getContactOptions = useCallback(() => {
    return contacts?.map((contact) => ({
      label: contact.name ?? 'N/A',
      value: contact.id ?? 0,
    })) ?? []
  }, [contacts])

  const consignments = watch('consignments') || [];

  const usedLocationIds = consignments.reduce((ids: number[], consignment: any) => {
    if (consignment.shipFrom && consignment.shipFrom.locationId) {
      ids.push(consignment.shipFrom.locationId);
    }
    if (consignment.deliverTo && consignment.deliverTo.locationId) {
      ids.push(consignment.deliverTo.locationId);
    }
    return ids;
  }, []);


  return (
    <>
      {ModalComponent}
      <FlowSection icon={<RoomIcon />}>
        <StyledAccordion
          defaultExpanded
          sx={{ marginTop: '10px' }}
          onChange={handleToggleAccordion}
        >
          <StyledAccordionSummary
            index={`shipfrom-content-${index}`}
            expanded={expanded}
            title={formatMessage(messages.shipFrom)}
          >
            {!expanded && (
              <Grid container>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <LabelItem>{formatMessage(messages.shipFrom)}</LabelItem>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <InfoDisplayItem label={formatMessage(messages.shipFrom)} value={getLocationName(shipFrom) ?? 'N/A'} isSmallScreen={false} />
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <InfoDisplayItem label={formatMessage(messages.scheduledDate)} value={loadingDate ?? 'N/A'} isSmallScreen={false} />
                </Grid>
              </Grid>)}
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name={`consignments.${index}.shipFrom.locationId`}
                  label={formatMessage(messages.location)}
                  options={getAvailableLocations(locationOptions, usedLocationIds)}
                  allOptions={locationOptions}
                  onChange={(e) => {
                    setValue(`consignments.${index}.shipFrom.contactId`, undefined)
                  }}
                  actionLabel={
                    <Button
                      size="small"
                      onClick={() => {
                        openModal();
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      {formatMessage(commonMessages.addNew)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDatePickerInput
                  name={`consignments.${index}.shipFrom.loadingDate`}
                  label={formatMessage(messages.scheduledDate)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormSelect
                  name={`consignments.${index}.shipFrom.contactId`}
                  label={formatMessage(messages.contact)}
                  options={getContactOptions()}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    marginTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography variant="subtitle1" sx={{ textAlign: 'left', color: '#636363' }}>
                    {formatMessage(messages.returnTo)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography sx={{ marginRight: 1, fontSize: '12px', color: '#636363' }}>
                      {formatMessage(messages.sameAsDefault)}
                    </Typography>
                    <Controller
                      name={`consignments.${index}.shipFrom.returnTo`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Switch {...field} />
                        </>
                      )}
                    />
                    <Typography sx={{ marginLeft: 1, fontSize: '12px', color: '#636363' }}>
                      {formatMessage(messages.different)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom sx={{ color: '#636363' }}>
                  {formatMessage(messages.selectedLocation)}
                </Typography>
                <Typography variant="body2" sx={{ color: '#636363' }}>Location 1 (Address Of Location 1)</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={<Switch />}
                  label={formatMessage(messages.clearingRequired)}
                  name={`consignments.${index}.shipFrom.clearingRequired`}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
        <ReturnTo consignmentIndex={index} />
      </FlowSection>
      <WaypointContainer consignmentIndex={index} />
    </>
  );
};

export default ShipFromForm;
