import RoomIcon from '@mui/icons-material/Room';

import {
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetContactByEntityIdQuery } from '../../app/redux-fetch/apiCoreQuery';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import FormDatePickerInput from '../../components/Forms/FormDatePickerInput';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../components/Typographies/LabelItem';
import FlowSection from './FlowSection';
import { useLocationModal } from './hooks/useLocationModal';
import messages from './messages';
import { getAvailableLocations } from './utils';


interface DeliverToFormProps {
  index: number
}

const DeliverToForm: React.FC<DeliverToFormProps> = ({ index }) => {

  const { control, setValue, watch } = useFormContext()
  const { formatMessage } = useIntl();

  const [expanded, setExpanded] = useState(true);

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  const customerId = watch('customerId');


  const { openModal, ModalComponent, locationOptions } = useLocationModal({
    onSave: (id) => {
      setValue(`consignments.${index}.deliverTo.location`, id)
    },
    customerId,
  });

  const getLocationName = (locationId: number) => {
    return locationOptions.find((location) => location.value === locationId)?.label;
  }

  const location = watch(`consignments.${index}.deliverTo.locationId`);
  const deliveryDate = dayjs(watch(`consignments.${index}.deliverTo.deliveryDate`)).format('DD/MM/YYYY');

  const { data: contacts } = useGetContactByEntityIdQuery(location, {
    skip: !location,
    refetchOnMountOrArgChange: true,
  })

  const getContactOptions = useCallback(() => {
    return contacts?.map((contact) => ({
      label: contact.name ?? 'N/A',
      value: contact.id ?? 0,
    })) ?? []
  }, [contacts])

  const consignments = watch('consignments') || [];

  const usedLocationIds = consignments.reduce((ids: number[], consignment: any) => {
    if (consignment.shipFrom && consignment.shipFrom.locationId) {
      ids.push(consignment.shipFrom.locationId);
    }
    if (consignment.deliverTo && consignment.deliverTo.locationId) {
      ids.push(consignment.deliverTo.locationId);
    }
    return ids;
  }, []);


  return (<>
    {ModalComponent}
    <FlowSection icon={<RoomIcon />} hasBorder={false}>
      <StyledAccordion defaultExpanded sx={{ marginBottom: 2 }} expanded={expanded} onChange={handleToggleAccordion}>
        <StyledAccordionSummary
          index={`deliverto-content-${index}`}
          expanded={expanded}
          title={formatMessage(messages.deliverTo)}
        >
          <Grid container>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <LabelItem>{formatMessage(messages.deliverTo)}</LabelItem>
            </Grid>
            {!expanded && (
              <>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <InfoDisplayItem label={formatMessage(messages.deliverTo)} value={getLocationName(location) ?? 'N/A'} isSmallScreen={false} />
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <InfoDisplayItem label={formatMessage(messages.deliveryDate)} value={deliveryDate ?? 'N/A'} isSmallScreen={false} />
                </Grid>
              </>)}
          </Grid>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormSelect
                name={`consignments.${index}.deliverTo.locationId`}
                label={formatMessage(messages.location)}
                options={getAvailableLocations(locationOptions, usedLocationIds)}
                allOptions={locationOptions}
                onChange={(e) => {
                  setValue(`consignments.${index}.deliverTo.contactId`, undefined)
                }}
                actionLabel={<Button size="small" onClick={() => { openModal() }} sx={{ textTransform: 'none', p: 0, mb: '6px' }}>
                  Add new
                </Button>}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormDatePickerInput
                name={`consignments.${index}.deliverTo.deliveryDate`}
                label={formatMessage(messages.deliveryDate)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormSelect
                name={`consignments.${index}.deliverTo.contactId`}
                label={formatMessage(messages.contact)}
                options={getContactOptions()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                name={`consignments.${index}.deliverTo.deliveryInstructions`}
                label={formatMessage(messages.deliveryInstructions)}
                placeholder={formatMessage(commonMessages.pleaseEnter)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#636363' }}>
                {formatMessage(messages.selectedLocation)}
              </Typography>
              <Typography variant="body2" sx={{ color: '#636363' }}>Location 1 (Address Of Location 1)</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box >
                <Controller
                  name={`consignments.${index}.deliverTo.clearingRequired`}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={formatMessage(messages.clearingRequired)}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion >
    </FlowSection>

  </>
  )
}

export default DeliverToForm
