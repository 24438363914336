import { FormControlLabel, Grid, Switch } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetMeasureUnitsQuery } from '../../app/redux-fetch/apiQuery';
import { useGetTemperatureRangesQuery } from '../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../app/utils';
import CombinedSelect from '../../components/Forms/CombinedSelect';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import messages from './messages';

interface TemperatureControlledFormProps {
    consignmentIndex: number;
    packagingIndex: number;
}

const TemperatureControlledForm: React.FC<TemperatureControlledFormProps> = ({ consignmentIndex, packagingIndex }) => {
    const { control, watch, setValue } = useFormContext();
    const isTemperatureControlled = watch(`consignments.${consignmentIndex}.packaging.${packagingIndex}.temperatureControlled`);

    const unit = watch(`consignments.${consignmentIndex}.packaging.${packagingIndex}.setPointUnitId`);

    const { data: measureUnits = [] } = useGetMeasureUnitsQuery()
    const { data: temperatureRange = [] } = useGetTemperatureRangesQuery()

    const temperatureUnitsOptions = useMemo(() => {
        return convertToLabelValue(measureUnits.filter(x => x.measureTypeId === 3).map(x => {
            return { name: x.units, id: x.id }
        }))
    }, [measureUnits])


    const temperatureRangeOptions = useMemo(() => {
        return convertToLabelValue(temperatureRange)
    }, [temperatureRange])

    const getTemperatureUnit = useCallback((id: number) => {
        return measureUnits.find((unit) => unit.id === id)?.units;
    }, [measureUnits])

    const { formatMessage } = useIntl();
    const resetTemperatureControlled = useCallback(() => {
        const fieldsToReset = [
            'temperatureRange',
            'setPoint',
            'setPointUnitId',
            'lowerWarning',
            'upperWarning',
            'lowerCritical',
            'upperCritical'
        ];
        fieldsToReset.forEach(field => setValue(`consignments.${consignmentIndex}.packaging.${packagingIndex}.${field}`, undefined));
    }, [consignmentIndex, packagingIndex, setValue]);

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
                <Controller
                    name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.temperatureControlled`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Switch {...field} checked={field.value}
                                onChange={(e) => {
                                    field.onChange(e.target.checked)
                                    resetTemperatureControlled()
                                }} />}
                            label={formatMessage(messages.temperatureControlled)}
                        />
                    )}
                />
            </Grid>
            {isTemperatureControlled && (
                <>
                    <Grid item xs={12} md={2}>
                        <FormSelect
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.temperatureRange`}
                            label={formatMessage(messages.temperatureRange)}
                            options={temperatureRangeOptions}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CombinedSelect
                            nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.setPoint`}
                            nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.setPointUnitId`}
                            labelValue={formatMessage(messages.temperatureSetPoint)}
                            labelUnitValue={formatMessage(messages.unitLabel)}
                            optionsUnit={temperatureUnitsOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormInput
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.lowerWarning`}
                            label={formatMessage(messages.lowerWarning)}
                            InputLabelProps={{ shrink: true }}
                            type='number'
                            unit={getTemperatureUnit(unit)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormInput
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.upperWarning`}
                            label={formatMessage(messages.upperWarning)}
                            InputLabelProps={{ shrink: true }}
                            type='number'
                            unit={getTemperatureUnit(unit)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormInput
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.lowerCritical`}
                            label={formatMessage(messages.lowerCritical)}
                            InputLabelProps={{ shrink: true }}
                            type='number'
                            unit={getTemperatureUnit(unit)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormInput
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.upperCritical`}
                            label={formatMessage(messages.upperCritical)}
                            InputLabelProps={{ shrink: true }}
                            type='number'
                            unit={getTemperatureUnit(unit)}
                            fullWidth
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default TemperatureControlledForm;
