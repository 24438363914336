import { useEffect, useState } from 'react';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useModalWithForm } from '../../../app/hooks/useModalWithForm';
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../../app/utils';
import { Option } from '../../../components/Forms/FormSelect';

interface UseLocationModalProps {
    onSave: (data: any) => void;
    customerId?: number;
    filterFunction?: (location: any) => boolean;
}

export const useLocationModal = ({ onSave, filterFunction, customerId }: UseLocationModalProps) => {
    const { tenantId } = useLoggedInUser();

    const [locationOptions, setLocationOptions] = useState<Option[]>([]);

    const { data: locations = [] } = useGetLocationsQuery({
        tenantId,
        customerId,
    });

    useEffect(() => {
        let filteredLocations = locations;

        if (filterFunction) {
            filteredLocations = locations.filter(filterFunction);
        }

        setLocationOptions(convertToLabelValue(filteredLocations));
    }, [locations, filterFunction]);

    const { isModalOpen, openModal, closeModal, ModalComponent } = useModalWithForm({
        type: 'location',
        onSave: (createdLocation) => {
            onSave(createdLocation.data.id);
        },
        data: {
            customerId: customerId,
            enabled: true,
        },
    });

    return {
        locationOptions,
        isModalOpen,
        openModal,
        closeModal,
        ModalComponent,
    };
};
